<template>
    <div class="view pa24">
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="goodsName" align="center" label="分销商品" show-overflow-tooltip />
                <el-table-column prop="userName" align="center" label="购买用户" show-overflow-tooltip />
                <el-table-column prop="fromUserName" align="center" label="受益人" show-overflow-tooltip />
                <el-table-column prop="payPrice" align="center" width="" label="实付金额" show-overflow-tooltip />
                <el-table-column show-overflow-tooltip prop="balance" align="center" label="分佣金额" />
                <el-table-column show-overflow-tooltip prop="scale" width="90" align="center" label="分佣比例" />
                <el-table-column prop="statusName" align="center" label="状态" width="90">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0">未结算</el-tag>
                        <el-tag v-if="scope.row.status == 1">已结算</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="disassociate(scope.row)" icon="el-icon-info" title="确定要结算这条分销？">
                            <el-button type="text" @click="edit(scope.row)">结算</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column> -->
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="centerDialogVisible" :destroy-on-close="true" width="400px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="等级名称" prop="levelName">
                    <el-input class="w240" v-model="formData.levelName" placeholder="请输入等级名称"></el-input>
                </el-form-item>
                <el-form-item label="升级顺序" prop="levelSort">
                    <el-input class="w240" placeholder="请输入升级顺序" v-model="formData.levelSort" type="number" />
                </el-form-item>
                <el-form-item label="积分价格" prop="levelTarget">
                    <el-input class="w240" v-model="formData.levelTarget" placeholder="请输入积分价格"></el-input>
                </el-form-item>
                <el-form-item label="购买价格" prop="levelPrice">
                    <div class="switchInput">
                        <div class="selectType">
                            <el-switch v-model="levelPrice" active-text="付款金额" inactive-text="免费" :active-value="1"
                                :inactive-value="0">
                            </el-switch>
                        </div>
                        <div class="levelPrice" v-if="levelPrice === 1">
                            <el-input type="number" class="w120 suffixInput" v-model="formData.levelPrice"
                                placeholder="购买价格">
                                <template slot="append">元</template>
                            </el-input>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="有效期" prop="levelTime">
                    <div class="switchInput">
                        <div class="selectType">
                            <el-switch v-model="levelTime" active-text="有效期" inactive-text="永久" :active-value="1"
                                :inactive-value="0">
                            </el-switch>
                        </div>
                        <div class="levelTime" v-if="levelTime === 1">
                            <el-input placeholder="可用天数" class="w120" suffix-icon="el-icon-date"
                                v-model="formData.levelTime" type="number" />
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUpdateLevel">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getMemberDistributorRecordList, setMemberLevel } from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
    name: "customerArticles",
    components: {
        commonTable,
    },
    data() {
        return {
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            centerDialogVisible: false,
            tableData: [],
            userInfo: this.$store.state.loginRoot.userInfo,
            modelTitle: "",
            formData: {
                levelName: "",
                levelPrice: "",
                levelTarget: "",
                levelTime: "",
                levelSort: "",
            },
            levelPrice: 0,
            levelTime: 0,
            rules: {
                levelName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入等级名称",
                },
                levelSort: {
                    required: true,
                    trigger: "blur",
                    message: "请输入升级顺序",
                },
            },
            formType: "",
            levelId: 0,
        };
    },
    filters: {
        getPrice(price) {
            if (price === 0) {
                return "免费";
            } else {
                return price + "元";
            }
        },
        getTime(time) {
            if (time === 0) {
                return "永久";
            } else {
                return time + "天";
            }
        },
    },
    created() {
        this.getMemberDistributorRecordList();
    },
    computed: {
        setDataTimeSec() {
            return (data) => getDataTimeSec(data);
        },
    },
    methods: {
        addUpdateLevel() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        companyId: this.userInfo.companyId,
                        // companyId:2
                    };
                    if (this.levelPrice === 0) {
                        params.levelPrice = 0;
                    }
                    if (this.levelTime === 0) {
                        params.levelTime = 0;
                    }
                    if (this.formType === "update") {
                        params.levelId = this.levelId;
                    }
                    setMemberLevel(params)
                        .then((res) => {
                            if (res.code === 200) {
                                this.$message({
                                    message: "保存成功",
                                    type: "success",
                                });
                                this.centerDialogVisible = false;
                                this.getMemberDistributorRecordList();
                            } else {
                                this.$message({
                                    message: res.message,
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => {
                            this.$message({
                                message: res.message,
                                type: "error",
                            });
                        });
                }
            });
        },
        /**@method 获取分销记录列表 */
        async getMemberDistributorRecordList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyId: this.userInfo.companyId,
                // companyId: 2,
                ...payload,
            };
            try {
                this.loading = true;
                const result = await getMemberDistributorRecordList(params);
                this.loading = false;

                const { data } = result;
                let tableData = [];
                let status = { 0: "未结算", 1: "已结算" };
                for (let row of data.pageInfo.list) {
                    row.statusName = status[row.status]
                    row.scale = row.scale * 100 + "%";
                    row.payPrice = "￥" + (row.payPrice / 100).toFixed(2);
                    row.balance = "￥" + (row.balance).toFixed(2);
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                //-console.log(error);
                this.loading = false;
                this.tableData = [];
            }
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getMemberDistributorRecordList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getMemberDistributorRecordList();
        },
        addMemberFun() {
            this.centerDialogVisible = true;
            this.modelTitle = "新增会员等级";
            this.formType = "add";
            this.formData = {
                levelName: "",
                levelPrice: "",
                levelTarget: "",
                levelTime: "",
                levelSort: "",
            };
            this.levelId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        edit(val) {
            this.centerDialogVisible = true;
            this.modelTitle = "编辑会员等级";
            this.formType = "update";
            this.levelId = val.levelId;
            if (val.levelPrice) {
                this.levelPrice = 1;
            }
            if (val.levelTime) {
                this.levelTime = 1;
            }
            this.formData = {
                levelName: val.levelName,
                levelPrice: val.levelPrice,
                levelTarget: val.levelTarget,
                levelTime: val.levelTime,
                levelSort: val.levelSort,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }

    .levelPrice {
        width: 120px;
    }

    .levelTime {
        width: 120px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>